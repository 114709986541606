import { Injectable } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { Store } from '@ngrx/store';
import { HttpService } from './http.service';
import * as UserActions from '../_actions/user.action';
import { TokenUtil } from '../_utils/token.util';
import { IAppReducerState } from '../_reducers';
import { IErrorResponse, IUser, INursingHome } from '../_interfaces';
import { Broadcaster } from './broadcast.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { ToastService } from '../_services/toast.service';

interface ILoginRequestParams {
  email: string;
  password: string;
}

@Injectable()
export class AuthService {
  redirectPath = '';

  constructor(private http: HttpService,
              private bc: Broadcaster,
              private ts: ToastService,
              private store: Store<IAppReducerState>,
              private router: Router,
              private angularFireAuth: AngularFireAuth) {
  }

  login(params: ILoginRequestParams) {
    this.ts.info('ログイン中...');
    this.angularFireAuth.auth.signInWithEmailAndPassword(params.email, params.password)
    .then( auth => {
        if (this.angularFireAuth.auth.currentUser == null) {
            return;
        }
      }
    ).then(() => {
      this._fetchUserDetail((user) => {
        if (user.role !== 'root') {
          this.ts.error('ログイン権限が不正なアカウントです。アカウントを見直してください');
          TokenUtil.remove();
          this.angularFireAuth.auth.signOut();
          this.bc.broadcast('RouterUpdate', {
            role: null,
            callback: () => this.router.navigate([this.redirectPath || '/login'])
          });
        } else {
          this.angularFireAuth.auth.currentUser.getIdToken(false).then(function(data) {
            if (typeof data !== 'string') {
              return;
            }
            TokenUtil.set(<string>data);
          });
          this.store.dispatch(new UserActions.Fetch(user));
          this.store.dispatch(new UserActions.Login());
          this.ts.success('ログインしました.');
          this.bc.broadcast('RouterUpdate', {
            role: user.role,
            callback: () => this.router.navigate([this.redirectPath || '/'])
          });
        }
      });
    });
  }

  logout() {
    this.angularFireAuth.auth.signOut();
    this.redirectPath = location.pathname;
    this.store.dispatch(new UserActions.Logout());
    TokenUtil.remove();
    this.bc.broadcast('RouterUpdate', {
      role: null,
      callback: () => this.router.navigate(['/login'])
    });
  }

  private _fetchUserDetail(callback: (data?: any) => void): void {
    this.http.get<IUser>('/api/v2/pro/users/_self', null).subscribe((user) => {
      this.http.get<INursingHome>('/api/v2/pro/nursing-homes/:id', {id: user.nursingHomeId}).subscribe(nursingHome => {
        user.nursingHomeGroupId = nursingHome.nursingHomeGroupId;
        user.role = user.professionalRole;
        callback(user);
      });
    });
  }
}
