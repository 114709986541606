import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ErrorHandler, Injector, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { MdlModule } from '@angular-mdl/core';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { ToastrModule } from 'ngx-toastr';
import { AngularFireModule} from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { NgSelectModule } from '@ng-select/ng-select';

// reducers
import { reducers } from './_reducers';

// modules
import { DashboardModule } from './dashboard/dashboard.module';
import { LoginModule } from './login/login.module';
import { AppRoutingModule } from './app-routing.module';
import { HeaderModule } from './header/header.module';
import { AppConfig } from './app.config';

// Services
import { CustomErrorHandler, CustomRouter } from './app.service';
import { HttpService } from './_services/http.service';
import { AnnouncementService } from './_services/announcement.service';
import { AuthService } from './_services/auth.service';
import { Broadcaster } from './_services/broadcast.service';
import { BreadcrumbService } from './_services/breadcrumb.service';
import { DiaperDeliveryService } from './_services/diaper-delivery.service';
import { DiaperService } from './_services/diaper.service';
import { DiaperStockService } from './_services/diaper-stock.service';
import { CompanyService } from './_services/company.service';
import { CareRecordService } from './_services/care-record.service';
import { NursingHomeService } from './_services/nursing-home.service';
import { NursingHomeUnitService } from './_services/nursing-home-unit.service';
import { ToastService } from './_services/toast.service';
import { UserService } from './_services/user.service';
import { SensorService } from './_services/sensor.service';
import { ResidentService } from './_services/resident.service';
import { ResidentBDeviceService } from './_services/resident-b-device.service';
import { DeviceService } from './_services/device.service';
import { BDeviceService } from './_services/b-device.service';
import { BDeviceDataService } from './_services/b-device-data.service';
import { OrderService } from './_services/order.service';
import { OrderMailSettingService } from './_services/order-mail-setting.service';
import { NotificationService } from './_services/notification.service';
import { NursingHomeDiaperService } from './_services/nursing-home-diaper.service';
import { PdfService } from 'app/_services/pdf.service';
import { LifeService } from 'app/_services/life.service';
import { LifeCarePlanningService } from 'app/_services/life-care-planning.service';

// Interceptors
import { CustomHttpInterceptor } from './_interceptors/http.interceptor';

// components
import { RootLeftMenuComponent } from './root-left-menu/root-left-menu.component';
import { AppComponent } from './app.component';

// Constants
import { CONSTANTS } from './_constants';
import { GraphQLModule } from './graphql.module';
import { DiaperTableComponent } from './_components/diaper-table/diaper-table.component';

export function httpFactory(injector: Injector, toastService: ToastService, http: HttpClient) {
  return new HttpService(injector, toastService, http);
}

export function initFactory(cs: CustomRouter) {
  return () => cs.load();
}

@NgModule({
  declarations: [
    AppComponent,
    RootLeftMenuComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    DashboardModule,
    LoginModule,
    AppRoutingModule,
    MdlModule,
    StoreModule.forRoot(reducers),
    CommonModule,
    BrowserAnimationsModule, // ToastrModuleで要求される
    ToastrModule.forRoot(),
    HeaderModule,
    AngularFireModule.initializeApp(AppConfig.getConfig().firebase),
    AngularFireAuthModule,
    NgxDatatableModule,
    GraphQLModule,
    MatButtonModule,
    MatDialogModule,
    NgSelectModule,
  ],
  providers: [
    {provide: ErrorHandler, useClass: CustomErrorHandler},
    {provide: Broadcaster, useClass: Broadcaster},
    {provide: CustomRouter, useClass: CustomRouter},
    {provide: 'CONSTANTS', useValue: CONSTANTS},
    {provide: AuthService, useClass: AuthService},
    {provide: AnnouncementService, useClass: AnnouncementService},
    {provide: UserService, useClass: UserService},
    {provide: BreadcrumbService, useClass: BreadcrumbService},
    {provide: CompanyService, useClass: CompanyService},
    {provide: CareRecordService, useClass: CareRecordService},
    {provide: NursingHomeService, useClass: NursingHomeService},
    {provide: NursingHomeUnitService, useClass: NursingHomeUnitService},
    {provide: SensorService, useClass: SensorService},
    {provide: ResidentService, useClass: ResidentService},
    {provide: ResidentBDeviceService, useClass: ResidentBDeviceService},
    {provide: DeviceService, useClass: DeviceService},
    {provide: BDeviceService, useClass: BDeviceService},
    {provide: BDeviceDataService, useClass: BDeviceDataService},
    {provide: DiaperService, useClass: DiaperService},
    {provide: DiaperDeliveryService, useClass: DiaperDeliveryService},
    {provide: DiaperStockService, useClass: DiaperStockService},
    {provide: OrderService, useClass: OrderService},
    {provide: OrderMailSettingService, useClass: OrderMailSettingService},
    {provide: NotificationService, useClass: NotificationService},
    {provide: NursingHomeDiaperService, useClass: NursingHomeDiaperService},
    {provide: PdfService, useClass: PdfService},
    {provide: LifeService, useClass: LifeService},
    {provide: LifeCarePlanningService, useClass: LifeCarePlanningService},
    {
      provide: HttpService,
      useFactory: httpFactory,
      deps: [Injector, ToastService, HttpClient]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initFactory,
      deps: [CustomRouter, HttpService],
      multi: true
    },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
