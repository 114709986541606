// staging development env & staging DB
export const environment = {
  production: false,
  baseUrl: 'https://dev-dashboard.dfree.io',
  apiUrl: 'https://dev-api.dfree.io',
  displayErrors: false,
  firebase: {
    apiKey: 'AIzaSyD68k8BuobEH9n9nn9l-_jST3MRpFQr4GM',
    projectId: 'dev-dfree-cons',
  },
};
