import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppReducerState } from './_reducers';
import { Broadcaster, EVENT_LOADING, EVENT_RESOLUTION } from './_services/broadcast.service';
import { IUserReducer } from './_interfaces';
import { FADE_OUT } from './_animations';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [FADE_OUT]
})
export class AppComponent implements OnInit {
  user: IUserReducer;
  isPageLoading = false;
  resolution = 0;

  constructor(private broadcaster: Broadcaster,
              private store: Store<IAppReducerState>) {
  }

  ngOnInit() {
    this.store.select('user').subscribe((user: IUserReducer) => {
      this.user = user;
    });

    this.broadcaster.on<boolean>(EVENT_LOADING).subscribe((isLoading) => {
      this.isPageLoading = isLoading;
    });

    this._updateResolution();
  }

  private _updateResolution(): void {
    const displayWidth: number = window.innerWidth;
    const zoom: number = (this.resolution > 0) ? displayWidth / this.resolution : 1;

    document.body.style.zoom = zoom.toString();
  }

}
