import { combineLatest as observableCombineLatest, Subscription, Observable } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from '../_services/auth.service';
import { NursingHomeService } from '../_services/nursing-home.service';
import { UserService } from '../_services/user.service';
import { BreadcrumbService } from '../_services/breadcrumb.service';
import { IAppReducerState } from '../_reducers';
import { IBreadcrumbReducer, ICompany, IUser, INursingHome, IResident } from '../_interfaces';
import { TOGGLE_ANIMATION } from '../_animations';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [TOGGLE_ANIMATION]
})
export class HeaderComponent implements OnInit, OnDestroy {
  breadcrumb: IBreadcrumbReducer;
  company: ICompany;
  user: IUser;
  residents: IResident[];
  nursingHomeId: number;
  activeResidentId: number;
  isShowAccountMenu = false;
  isShowDashboardSetting = false;
  private subs = new Subscription();

  constructor(private router: Router,
              private as: AuthService,
              private store: Store<IAppReducerState>,
              private nhs: NursingHomeService,
              private us: UserService,
              public bs: BreadcrumbService,
              @Inject('CONSTANTS') public CONSTANTS) {
  }

  ngOnInit() {
    this.subs.add(
      observableCombineLatest(
        this.store.select('breadcrumb'),
        this.store.select('company'),
        this.store.select('user'))
        .subscribe(([breadcrumb, company, user]) => {
          this.breadcrumb = breadcrumb;
          this.company = company.company;
          this.activeResidentId = company.activeResidentId;
          this.user = user.detail;
          if (this.user) {
            this.us.fetchUser(this.user.id).subscribe((userData: IUser) => {
              // store(_self)で取得できなかった要素を取得
              this.user.email = userData.email;
              this.user.name = userData.name;
              this.user.role = userData.professionalRole;
            });
          }
        })
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  clickLogout() {
    this.as.logout();
    this.router.navigate(['/login']);
  }

  clickAccountSetting() {
    let path;
    const {ROOT, ADMIN, MEMBER} = this.CONSTANTS.ROLE;
    switch (this.user.role) {
      case ROOT.value:
        path = `/companies/${this.user.nursingHomeGroupId}/accounts/${this.user.id}/edit`;
        break;
      case ADMIN.value:
        path = `/accounts/${this.user.id}/edit`;
        break;
      case MEMBER.value:
        path = `/accounts/${this.user.id}/edit`;
        break;
    }
    this.router.navigate([path]);
    this.isShowAccountMenu = false;
  }

  clickUser() {
    let path;
    const {ROOT, ADMIN, MEMBER} = this.CONSTANTS.ROLE;
    switch (this.user.role) {
      case ROOT.value:
        path = `/companies/${this.user.nursingHomeGroupId}/accounts/${this.user.id}`;
        break;
      case ADMIN.value:
        path = `/accounts/${this.user.id}`;
        break;
      case MEMBER.value:
        path = `/accounts/${this.user.id}`;
        break;
    }
    this.router.navigate([path]);
    this.isShowAccountMenu = false;
  }

  navigate(path: string): void {
    const {ROOT, ADMIN, MEMBER} = this.CONSTANTS.ROLE;
    switch (this.user.role) {
      case ROOT.value:
        path = `/companies/${this.company.id}${path}`;
        break;
      case ADMIN.value:
        break;
      case MEMBER.value:
        break;
    }
    this.router.navigate([path]);
  }

  getSelectedNursingHome(id: number): INursingHome {
    if (!this.company) {
      return;
    }

    if (typeof id === 'string') {
      id = parseInt(id, 10);
    }

    const nursingHome = this.company.nursingHomes.find((v) => v.id === id);
    if (nursingHome) {
      this.residents = nursingHome.residents;
      this.nursingHomeId = nursingHome.id;
    }
    return nursingHome;
  }

  getSelectedResident(): IResident {
    if (!this.residents) {
      return;
    }

    return this.residents.find((v) => v.id === this.activeResidentId);
  }
}
